import { useCallback, useEffect, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { AuthTemplate, useCheckIdentifyAuthType } from '@ui';

import { NotFound } from '@pages';
import { AuthPage, RegisterPage } from '@features/user/ui';
import { AuthTypes } from '@teledoctor/common/dist/types/auth';
import { AuthBlock } from '@features/user/ui/block';

export const AuthAfterPurchase = () => {
  const [isChecked, setCheckingStatus] = useState<boolean>(false);
  const [authType, setAuthType] = useState<AuthTypes | ''>('');

  const { url } = useRouteMatch();

  const { handleCheckIdentify } = useCheckIdentifyAuthType({
    setAuthType,
    setCheckingStatus,
  });

  const number = localStorage.getItem('numberAfterPurchase');

  useEffect(() => {
    !!number && handleCheckIdentify(number);
  }, [handleCheckIdentify, number]);

  const getAuthComponent = useCallback(() => {
    switch (authType) {
      case 'auth':
        return AuthPage;
      case 'register':
        return RegisterPage;
    }
  }, [authType]);

  return (
    <AuthTemplate>
      <AuthBlock>
        {!!isChecked && !!authType && (
          <Switch>
            <Route exact path={url} component={getAuthComponent()} />
            <Route path="*" component={NotFound} />
          </Switch>
        )}
      </AuthBlock>
    </AuthTemplate>
  );
};
