import { ChatMessageText } from '@features/chat/ui/chat-message-text';
import { ChatMessageSenderInfo } from '@features/chat/ui/chat/chat-message-sender-info';
import { MessageUI } from '@eus/react-client/src/chat_v2/types';
import { MessageType } from '@eus/chat-default-logic/src/messages';

interface Props {
  text: MessageUI['text'];
  user: MessageUI['user'];
  time: string;
  messageType: MessageType;
  chatMessageClasses: string;
}

export const ChatSimpleText = (props: Props) => {
  const { chatMessageClasses, text, time, messageType, user } = props;
  return (
    <div className={chatMessageClasses}>
      <div className="chat__message">
        <ChatMessageText text={text} />
        <div className="chat__message-time">{time}</div>
        <ChatMessageSenderInfo user={user} messageType={messageType} />
      </div>
    </div>
  );
};
