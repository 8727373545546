import { clearNode, createEvent, createStore } from 'effector';

import { withCountdown } from '@teledoctor/common/dist/lib/effector/with-countdown';

export type NotificationType = 'error' | 'done';

export type NotificationTarget =
  | 'global'
  | 'authorization'
  | 'chat-claim-modal'
  | 'chat-thanks-modal'
  | 'chat-mark-modal'
  | 'get-consultation'
  | 'add-card'
  | 'change-password'
  | 'restore-password'
  | 'registration'
  | 'datetime-picker';
export interface Notification {
  id: string;
  message: string;
  type: NotificationType;
  target: NotificationTarget;
  onClear?: () => void;
}

export const addNotification = createEvent<Notification>('notifications:add');
export const removeNotification = createEvent<string>('notifications:remove');
export const clearNotifications = createEvent('notifications:clear');

export const $notifications = createStore<Notification[]>([]);

$notifications
  .on(addNotification, (store, notification) => {
    const suchNotificationAlreadyExists = store.find(
      (existingNotification) =>
        existingNotification.message === notification.message,
    );
    if (suchNotificationAlreadyExists) {
      return;
    }
    return [...store, notification];
  })
  .on(removeNotification, (store, notificationId) => {
    return store.filter((notification) => notification.id !== notificationId);
  })
  .on(clearNotifications, (store) => []);

addNotification.watch(({ id, onClear }) => {
  const event = createEvent(id);

  const { stopped } = withCountdown(`notification-timer-${id}`, {
    start: event.map(() => 5),
  });

  event();

  const unsubscribe = stopped.watch(() => {
    removeNotification(id);
    onClear?.();
    clearNode(stopped);
    unsubscribe();
  });
});
