import { ServerErrorsHandler } from '@eus/react-client';
import { useAuthTdLk } from '@features/api';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  LoginFormValues,
  useLoginFormSchema,
} from '@teledoctor/common/dist/features/user/forms';
import { formServerErrorHandler } from '@teledoctor/common/dist/lib/error-handlers';
import { FeedParams } from '@teledoctor/common/dist/lib/requests';
import { addNotification, Button, FField, FormWrapper } from '@ui';
import { Dispatch, SetStateAction, useCallback, useContext } from 'react';
import { ErrorOption, useForm } from 'react-hook-form';
import styles from './styles.module.scss';
import { AuthorizationScreenTypes } from '../types';
import { WelcomeModalContext } from '@features/user/ui/modals';

interface Props {
  phone: string;
  changeScreen: Dispatch<SetStateAction<AuthorizationScreenTypes>>;
}

export const AuthByPassword = ({ phone, changeScreen }: Props) => {
  const { LoginFormSchema } = useLoginFormSchema();
  const methods = useForm<LoginFormValues>({
    shouldUnregister: false,
    resolver: yupResolver(LoginFormSchema),
    mode: 'onChange',
    defaultValues: { login: phone },
  });

  const { auth } = useAuthTdLk();

  const welcome = useContext(WelcomeModalContext);

  const handleLoginSuccess = useCallback(
    (
      { login, password }: LoginFormValues,
      setError: (name: keyof LoginFormValues, error: ErrorOption) => void,
    ) => {
      auth(
        {
          password,
          login,
        },
        setError,
        new FeedParams({
          needShowLoader: true,
          ignoreError: false,
          throwNext: true,
        }),
      )
        .then(() => {
          if (welcome) {
            welcome.showModal('auth');
          }
        })
        .catch(
          formServerErrorHandler({
            commonErrorCase: {
              commonErrorCallback: (errorText) =>
                addNotification({
                  id: 'auth' + Date.now(),
                  type: 'error',
                  message: errorText,
                  target: 'global',
                }),
            },
            fieldsErrorsCase: {
              fieldsErrorsCallback: ServerErrorsHandler(setError),
            },
          }),
        );
    },
    [auth, welcome],
  );

  return (
    <FormWrapper
      className="auth-form login-form"
      onSubmit={methods.handleSubmit((values) =>
        handleLoginSuccess(values, methods.setError),
      )}>
      <div className="form__fields">
        <FField.InputPassword
          id="enter-form-password"
          name="password"
          methods={methods}
          placeholder="Введите пароль"
          className="form__field"
          data-cy="AUTH_PASSWORD_INPUT"
        />
      </div>
      <div className="auth-form__footer">
        <Button className={styles.next} title="Далее" fullWidth />
        <Button
          type="button"
          title="Забыли пароль?"
          fullWidth
          appearance="textual"
          onClick={() => {
            changeScreen('restore');
          }}
        />
      </div>
    </FormWrapper>
  );
};
