import React, { useMemo } from 'react';
import classnames from 'classnames';
import './alert.scss';
import { Modal, ModalProps } from '../atoms/modal';
import { Button, ButtonProps } from '../atoms/button';

interface AlertProps extends ModalProps {
  isOpen: boolean;
  title: string;
  message: React.ReactNode;
  onClose: () => void;
  buttons: ButtonProps[];
  className?: string;
  short?: boolean;
}

export const Alert = ({
  isOpen,
  title,
  message,
  buttons,
  className,
  onClose,
  short = true,
  ...modalProps
}: AlertProps) => {
  const alertClassNames = classnames('alert', className);

  const renderMessage = useMemo(() => {
    return typeof message === 'string' ? <p>{message}</p> : message;
  }, [message]);
  return (
    <Modal
      short={short}
      {...modalProps}
      className={alertClassNames}
      isOpen={isOpen}
      header={title}
      footer={<AlertButtons onClose={onClose} buttons={buttons} />}
      onClose={onClose}>
      {renderMessage}
    </Modal>
  );
};

export interface AlertButtonsProps {
  onClose: () => void;
  buttons: ButtonProps[];
}

const AlertButtons = ({ buttons, onClose }: AlertButtonsProps) => {
  return (
    <div className="alert__buttons">
      {buttons.map((buttonProps, index) => {
        const handleClick = () => {
          buttonProps?.onClick?.();
          onClose();
        };

        return (
          <div className="alert__button" key={index}>
            <Button {...buttonProps} onClick={handleClick} />
          </div>
        );
      })}
    </div>
  );
};
