import React, { useEffect } from 'react';

import './styles.scss';
import { TitleLine, Breadcrumbs, BreadcrumbsItem } from '@ui';
import { ProfileEditForm } from '@features/user/ui';
import { push } from '@features/app/navigation.model';
import { useProfileTdLk } from '../../features/api/hooks';

const breadcrumbs: BreadcrumbsItem[] = [
  {
    path: '/lk/profile/',
    breadcrumb: 'Профиль',
  },
  {
    path: '/lk/profile/edit',
    breadcrumb: 'Редактировать',
  },
];

export const ProfileEditPage = () => {
  const { profile, load } = useProfileTdLk();

  useEffect(() => {
    load();
  }, [load]);

  const avatar = profile?.avatar_path;

  const handleProfileEditFormSuccess = () => {
    push({ path: '/lk/profile' });
  };

  return (
    <React.Fragment>
      <TitleLine>
        <Breadcrumbs props={breadcrumbs} />
      </TitleLine>
      {profile && (
        <ProfileEditForm
          defaultValues={profile}
          onSuccess={handleProfileEditFormSuccess}
        />
      )}
    </React.Fragment>
  );
};
