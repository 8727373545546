import { yupResolver } from '@hookform/resolvers/yup';
import {
  ChangePasswordAfterRestoreFormValues,
  useChangePasswordAfterRestoreSchema,
} from '@teledoctor/common/dist/features/user/forms';
import { Button, FField, FormWrapper } from '@ui';
import { useForm } from 'react-hook-form';

interface Props {
  onSuccess: (values: ChangePasswordAfterRestoreFormValues) => Promise<any>;
}

export const CreatePassword = ({ onSuccess }: Props) => {
  const { ChangePasswordAfterRestoreSchema } =
    useChangePasswordAfterRestoreSchema();

  const methods = useForm<ChangePasswordAfterRestoreFormValues>({
    resolver: yupResolver(ChangePasswordAfterRestoreSchema),
    mode: 'onBlur',
    shouldUnregister: false,
  });

  const { handleSubmit } = methods;

  return (
    <FormWrapper onSubmit={handleSubmit(onSuccess)}>
      <FField.InputPassword
        name="new_password"
        placeholder="Новый пароль"
        methods={methods}
        className="form__field"
        data-cy="RESTORE_PASSWORD_CODE_INPUT"
      />
      <FField.InputPassword
        name="confirm_new_password"
        placeholder="Повторите новый пароль"
        methods={methods}
        className="form__field"
        data-cy="RESTORE_PASSWORD_CODE_INPUT"
      />
      <Button fullWidth title="Сохранить" />
    </FormWrapper>
  );
};
