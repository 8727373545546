import { useAuthTdLk } from '@features/api';
import { formServerErrorHandler } from '@teledoctor/common/dist/lib/error-handlers';
import { FeedParams } from '@teledoctor/common/dist/lib/requests';
import { AuthTypes } from '@teledoctor/common/dist/types/auth';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { addNotification } from '../organisms';

interface Props {
  setAuthType: Dispatch<SetStateAction<'' | AuthTypes>>;
  setCheckingStatus: Dispatch<SetStateAction<boolean>>;
  beforeCheckingHandler?: (login: string) => void;
}

export const useCheckIdentifyAuthType = ({
  setAuthType,
  setCheckingStatus,
  beforeCheckingHandler,
}: Props) => {
  const { checkIdentify } = useAuthTdLk();

  const handleCheckIdentify = useCallback(
    (login: string) => {
      !!beforeCheckingHandler && beforeCheckingHandler(login);
      checkIdentify(
        { login },
        {
          onExist: () => {
            setAuthType('auth');
          },
          onNotExist: () => {
            setAuthType('register');
          },
        },
        new FeedParams({
          needShowLoader: true,
        }),
      )
        .catch(
          formServerErrorHandler({
            commonErrorCase: {
              commonErrorCallback: (errorText) => {
                return addNotification({
                  id: 'checkIdentify' + Date.now(),
                  type: 'error',
                  message: errorText,
                  target: 'authorization',
                });
              },
            },
            fieldsErrorsCase: {
              fieldsErrorsCallback: () => {},
            },
          }),
        )
        .finally(() => setCheckingStatus(true));
    },
    [checkIdentify, setAuthType, setCheckingStatus, beforeCheckingHandler],
  );

  return { handleCheckIdentify };
};
