import { createRef, useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { IMaskInput } from 'react-imask';
import { InputPhoneModal } from './modal';
import { useBoolean } from '@teledoctor/common/dist/hooks';
import { usePhoneCodes, ID_RU, Phone } from '@eus/react-client/src/phone-codes';

import './input-phone.scss';

const applyMask = (string: string, mask: string) => {
  let i = 0;
  return mask.replace(/0/g, (_) => {
    return string[i++];
  });
};

export interface InputPhoneProps {
  hasError?: boolean;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  className?: string;
  value?: string;
  placeholder?: string;
  id?: string;
  dataCyCodeSelect?: string;
  disabled?: boolean;
}

const mapMask = (mask: string) => mask.replace(/9/g, '0');

export const InputPhone = ({
  hasError,
  onChange,
  onBlur,
  value = '',
  id = '',
  className,
  placeholder,
  dataCyCodeSelect,
  disabled,
  ...props
}: InputPhoneProps) => {
  const {
    value: isModalOpen,
    setTruthy: openModal,
    setFalsy: closeModal,
  } = useBoolean(false);

  const { byId, getCountryForPhone } = usePhoneCodes();

  const [phoneCode, setPhoneCode] = useState(byId(ID_RU).data!);
  const [maskedFullValue, setMaskedFullValue] = useState<string>();

  const mask = mapMask(phoneCode.pm);

  const phonePart = ((value as string) || '').substring(
    phoneCode.pc.length + 1,
  );

  const setCode = useCallback(
    (newValue: Phone) => {
      setPhoneCode(newValue);
      onChange?.('+' + newValue.pc + ' ' + phonePart);
      closeModal();
    },
    [closeModal, onChange, phonePart],
  );

  const onPhoneChange = useCallback(
    (phone) => {
      onChange?.('+' + phoneCode.pc + ' ' + phone);
    },
    [onChange, phoneCode],
  );

  useEffect(() => {
    if (value) {
      getCountryForPhone(value, true).then((codeData) => {
        if (codeData) {
          setPhoneCode(codeData);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let masked = applyMask(phonePart, mask);
    const maskedFullPhone = '+' + phoneCode.pc + ' ' + masked;
    onChange?.(maskedFullPhone);
    setMaskedFullValue(maskedFullPhone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inputClassnames = classnames('input-phone', className, {
    'input-phone--has-error': hasError,
    'input-phone--is-modal-open': isModalOpen,
  });

  return (
    <div className={inputClassnames}>
      {!disabled && (
        <>
          <button
            className="input-phone__select"
            onClick={openModal}
            type="button"
            {...(props['data-cy']
              ? { 'data-cy': props['data-cy'] + '_CODE_SELECT' }
              : '')}>
            <span className="input-text">{`+${phoneCode.pc}`}</span>
            <span className="input-phone__select-arrow" />
          </button>
          <IMaskInput
            mask={mask}
            name="input-phone"
            unmask={false}
            value={phonePart}
            onAccept={onPhoneChange}
            className="input-text input-phone__input"
            onBlur={onBlur}
            placeholder={placeholder ?? ''}
            {...(id ? { id } : '')}
            {...props}
          />
          <InputPhoneModal
            current={phoneCode}
            visible={isModalOpen}
            onClose={closeModal}
            onSelectCountryCode={setCode}
          />
        </>
      )}

      {disabled && <div className="input-text">{maskedFullValue}</div>}
    </div>
  );
};
