import { useEffect, useMemo, useState } from 'react';
import styles from './style.module.scss';
import { Button, FField, FormWrapper } from '@ui';
import { ErrorOption, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CheckFormCode } from '@eus/react-client';
import { useTimer } from '@teledoctor/common/dist/hooks/use-timer';
import { getTimeDigit } from '@teledoctor/common/dist/lib/formatters/format-date';
import { useCheckCodeFormSchema } from '@teledoctor/common/dist/features/user/forms/request-code';

interface Props {
  phone?: string;
  email?: string;
  requestCodeAgain: (login: string) => void;
  onSuccessForm: (
    { code }: CheckFormCode,
    setError: (name: any, error: ErrorOption) => void,
  ) => void;
}

const $TIMER_INTERVAL = 300;

export const CodeForm = ({
  phone,
  email,
  requestCodeAgain,
  onSuccessForm,
}: Props) => {
  const { startTimer, timerSecondsLeft } = useTimer();
  const [isContinueButtonDisabled, setContinueButtonDisabled] =
    useState<boolean>();

  useEffect(() => {
    startTimer($TIMER_INTERVAL);
  }, [startTimer]);

  const { CheckCodeFormSchema } = useCheckCodeFormSchema();

  const methods = useForm<CheckFormCode>({
    resolver: yupResolver(CheckCodeFormSchema),
    mode: 'onChange',
  });

  const { setError, handleSubmit, formState } = methods;

  const { isValid } = formState;

  useEffect(() => {
    if (!isValid) {
      setContinueButtonDisabled(true);
    } else if (isContinueButtonDisabled) {
      setContinueButtonDisabled(false);
    }
  }, [isContinueButtonDisabled, setContinueButtonDisabled, isValid]);

  const onError = (error) => {};

  const requestCodeAgainHandler = () => {
    startTimer($TIMER_INTERVAL);
    if (phone) {
      requestCodeAgain(phone);
    }
    if (email) {
      requestCodeAgain(email);
    }
  };

  const getInfo: { placeholder: string; description?: string } | null =
    useMemo(() => {
      if (phone || email) {
        let placeholder = '';
        let description = '';

        if (phone) {
          placeholder = 'SMS';
          description = `номер ${phone}`;
        }
        if (email) {
          placeholder = 'E-mail';
          description = `почту ${email.substring(0, 3)}***@${
            email.split('@')[1]
          }`;
        }

        return {
          placeholder: `Код из ${placeholder}`,
          description: `Код отправили на ${description}`,
        };
      }
      return null;
    }, [phone, email]);

  return (
    <FormWrapper
      onSubmit={handleSubmit(
        (values) => onSuccessForm(values, setError),
        onError,
      )}
      className={styles.wrapper}>
      <FField.Input
        name="code"
        methods={methods}
        {...(getInfo?.placeholder && { placeholder: getInfo.placeholder })}
        className="form__field"
        data-cy="ENTER_CODE_INPUT"
      />
      <div className={styles.description}>
        {timerSecondsLeft ? (
          <>
            {!!getInfo?.description && getInfo.description}
            <br />
            Запросить новый код можно через {getTimeDigit(timerSecondsLeft)}
          </>
        ) : (
          <div className={styles.repeat} onClick={requestCodeAgainHandler}>
            Запросить код повторно
          </div>
        )}
      </div>
      <div className={styles.footer}>
        <Button
          fullWidth
          title="Подтвердить код"
          className={styles.continue}
          disabled={isContinueButtonDisabled}
        />
      </div>
    </FormWrapper>
  );
};
