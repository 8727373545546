import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { addNotification, Button, FField, FormWrapper } from '@ui';
import './form.scss';

import { ChangePasswordForm } from '@eus/react-client/src/auth/forms/changePassword';
import { ServerErrorsHandler } from '@eus/react-client/src/forms/serverErrorsHandler';
import { CommonFormProps } from '@teledoctor/common/dist/features/shared/forms';
import { usePasswordChangeFormSchema } from '@teledoctor/common/dist/features/user/forms';
import { formServerErrorHandler } from '@teledoctor/common/dist/lib/error-handlers';
import { useAuthTdLk } from '../../../../../features/api';

interface Props extends Omit<CommonFormProps<ChangePasswordForm>, 'onSuccess'> {
  onSuccess: () => void;
}

export const EditPasswordForm = ({ onSuccess, defaultValues }: Props) => {
  const { PasswordChangeFormSchema } = usePasswordChangeFormSchema();
  const methods = useForm<ChangePasswordForm>({
    resolver: yupResolver(PasswordChangeFormSchema),
    mode: 'onBlur',
    defaultValues,
  });

  const { setError, handleSubmit } = methods;
  const { changePassword } = useAuthTdLk();

  const onSubmit = ({
    old_password,
    new_password,
    new_password_confirm,
  }: ChangePasswordForm) => {
    return changePassword({
      old_password,
      new_password,
      new_password_confirm: new_password,
    })
      .then(() => onSuccess())
      .catch(
        formServerErrorHandler({
          commonErrorCase: {
            commonErrorCallback: (errorText) =>
              addNotification({
                id: 'change-password' + Date.now(),
                type: 'error',
                message: errorText,
                target: 'change-password',
              }),
          },
          fieldsErrorsCase: {
            fieldsErrorsCallback: ServerErrorsHandler(setError),
          },
        }),
      );
  };

  return (
    <FormWrapper onSubmit={handleSubmit(onSubmit)}>
      <FField.InputPassword
        name="old_password"
        methods={methods}
        label="Текущий пароль"
        placeholder="Введите пароль"
        className="form__field"
        data-cy="EDIT_PASSWORD_OLD_PASSWORD_INPUT"
      />
      <FField.InputPassword
        name="new_password"
        methods={methods}
        label="Новый пароль"
        placeholder="Введите новый пароль"
        className="form__field"
        data-cy="EDIT_PASSWORD_NEW_PASSWORD_INPUT"
      />
      <FField.InputPassword
        name="new_password_confirm"
        methods={methods}
        label="Подтвердить пароль"
        placeholder="Введите пароль еще раз"
        className="form__field"
        data-cy="EDIT_PASSWORD_NEW_PASSWORD_INPUT_CONFIRM"
      />
      <div className="form__row">
        <Button
          title="Продолжить"
          type="submit"
          className="-width__xs-100"
          data-cy="EDIT_PASSWORD_SUBMIT_BTN"
        />
      </div>
    </FormWrapper>
  );
};
