import {
  AdditionalPayload,
  MessageUI,
} from '@eus/react-client/src/chat_v2/types';
import { ChatMessageSenderInfo } from '../chat/chat-message-sender-info';
import { ChatSpecialist, ChatSimpleText } from './chat-custom-messages';
import { MessageType } from '@eus/chat-default-logic/src/messages';
import { ChatFiles } from './chat-files';

interface Props {
  text: MessageUI['text'];
  additionalPayload: string;
  user: MessageUI['user'];
  time: string;
  messageType: MessageType;
  chatMessageClasses: string;
}

export const ChatCustomMessage = ({
  additionalPayload,
  text,
  user,
  time,
  messageType,
  chatMessageClasses,
}: Props) => {
  const payload: AdditionalPayload = JSON.parse(additionalPayload);
  if (payload.type === 'user_info') {
    const { avatar, title, texts } = payload;
    return (
      <>
        {!!text && (
          <ChatSimpleText
            user={user}
            messageType={messageType}
            time={time}
            text={text}
            chatMessageClasses={chatMessageClasses}
          />
        )}
        <div className={chatMessageClasses}>
          <div className="chat__message">
            <ChatSpecialist avatar={avatar} title={title} texts={texts} />
            <div className="chat__message-time">{time}</div>
            <ChatMessageSenderInfo user={user} messageType={messageType} />
          </div>
        </div>
      </>
    );
  }

  if (payload.type === 'select_timeslot') {
    return (
      <ChatSimpleText
        user={user}
        messageType={messageType}
        time={time}
        text={text}
        chatMessageClasses={chatMessageClasses}
      />
    );
  }

  if (payload.type === 'files') {
    const files = payload.files.map((file) => ({
      name: file.original_name,
      url: file.path,
      size: file.size,
      id: file.id,
      type: file?.type ?? '',
      directUrl: '',
    }));
    return (
      <>
        {!!text && (
          <ChatSimpleText
            user={user}
            messageType={messageType}
            time={time}
            text={text}
            chatMessageClasses={chatMessageClasses}
          />
        )}

        <div className={chatMessageClasses}>
          <ChatFiles
            files={files}
            messageType={messageType}
            time={time}
            user={user}
          />
        </div>
      </>
    );
  }

  return null;
};
