import { useCallback, useContext, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import authStyles from './../styles.module.scss';
import { useAuthTdLk } from '@features/api';
import { FeedParams } from '@teledoctor/common/dist/lib/requests';
import { Button, addNotification, Notification } from '@ui';
import { AuthBack, CodeForm, WelcomeModalContext } from '@features/user/ui';
import {
  AuthFormCode,
  CheckFormCode,
  ServerErrorsHandler,
} from '@eus/react-client';
import { ErrorOption } from 'react-hook-form';
import { formServerErrorHandler } from '@teledoctor/common/dist/lib/error-handlers';
import { AuthByPassword } from './by-password/by-password';
import formatPhone from '../helpers/phone';
import { RestorePassword } from './restore-password';
import { AuthorizationScreenTypes } from './types';

interface Props {
  phone?: string;
  onBackHandler?: () => void;
}

export const AuthPage = ({ phone, onBackHandler }: Props) => {
  const [number, setNumber] = useState<string>('');
  const [type, setType] = useState<AuthorizationScreenTypes>('password');

  const welcome = useContext(WelcomeModalContext);

  const { checkIdentify, requestCodeTd, authByCode } = useAuthTdLk();

  const requestCodeAuthHandler = useCallback(
    (login: string) => {
      checkIdentify(
        { login },
        {
          onExist: () => {
            requestCodeTd(login, {
              onExist: () => {},
              onNotExist: () => {},
              errorHandler: (error) => {
                const notificationParams: Omit<Notification, 'message'> = {
                  id: `auth-notification-${Date.now()}`,
                  target: 'authorization',
                  type: 'error',
                };

                Array.isArray(error)
                  ? error.map((error) =>
                      addNotification({
                        ...notificationParams,
                        message: error,
                      }),
                    )
                  : addNotification({ ...notificationParams, message: error });
              },
            });
          },
          onNotExist: () => {
            addNotification({
              target: 'authorization',
              id: `auth-notification-${Date.now()}`,
              type: 'error',
              message:
                'Пользователь с таким номером не найден. Зарегистрируйтесь',
            });
          },
        },
        new FeedParams({
          throwNext: true,
          needShowLoader: true,
        }),
      );
    },
    [checkIdentify, requestCodeTd],
  );

  const onSuccessCode = useCallback(
    (
      { code }: CheckFormCode,
      setError: (
        name: keyof Omit<AuthFormCode, 'login'>,
        error: ErrorOption,
      ) => void,
    ) => {
      authByCode(
        {
          code,
          phone: number,
        },
        setError,
        new FeedParams({
          needShowLoader: true,
          ignoreError: false,
          throwNext: true,
        }),
      )
        .then(() => {
          if (welcome) {
            welcome.showModal('auth');
          }
        })
        .catch(
          formServerErrorHandler({
            commonErrorCase: {
              commonErrorCallback: (errorText) =>
                addNotification({
                  id: 'auth' + Date.now(),
                  type: 'error',
                  message: errorText,
                  target: 'global',
                }),
            },
            fieldsErrorsCase: {
              fieldsErrorsCallback: ServerErrorsHandler(setError),
            },
          }),
        );
    },
    [authByCode, welcome, number],
  );

  useEffect(() => {
    if (!number) {
      setNumber(
        formatPhone(phone ?? localStorage.getItem('numberAfterPurchase') ?? ''),
      );
      localStorage.removeItem('numberAfterPurchase');
    }
  }, [number, phone]);

  const getScreen = useCallback(() => {
    switch (type) {
      case 'code':
        return (
          <CodeForm
            phone={number}
            requestCodeAgain={requestCodeAuthHandler}
            onSuccessForm={onSuccessCode}
          />
        );
      case 'password':
        return (
          <>
            <AuthByPassword phone={number} changeScreen={setType} />
            <Button
              className={styles['password-button']}
              fullWidth
              appearance="textual"
              title="Войти по СМС"
              onClick={() => {
                requestCodeAuthHandler(number);
                setType('code');
              }}
            />
          </>
        );
      case 'restore':
        return <RestorePassword phone={number} />;
    }
  }, [number, onSuccessCode, requestCodeAuthHandler, type]);

  return (
    <>
      {type !== 'password' ? (
        <AuthBack
          className={authStyles.back}
          onClick={() => {
            setType('password');
          }}
        />
      ) : (
        !!onBackHandler && (
          <AuthBack className={authStyles.back} onClick={onBackHandler} />
        )
      )}
      {type !== 'restore' && <h1 className="h1">Авторизация</h1>}
      {!!number && getScreen()}
    </>
  );
};
