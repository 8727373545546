import React from 'react';

import lock from '@assets/img/lock.png';
import { Alert, Button, Modal, Notifications } from '@ui';
import { EditPasswordForm } from './forms';
import { useBoolean } from '@teledoctor/common/dist/hooks';

export const EditPassword = () => {
  const {
    value: isVisible,
    setTruthy: open,
    setFalsy: close,
  } = useBoolean(false);

  const {
    value: isSuccessVisible,
    setTruthy: openSuccess,
    setFalsy: closeSuccess,
  } = useBoolean(false);

  const onFormSentSuccess = () => {
    close();
    openSuccess();
  };

  return (
    <React.Fragment>
      <Button
        className="button button__primary titleline__button"
        title="Изменить пароль"
        onClick={open}
        daya-cy="EDIT_PASSWORD_BTN"
      />
      <Modal
        onClose={close}
        isOpen={isVisible}
        header="Изменить пароль"
        className="edit-form-modal"
        image={lock}>
        <EditPasswordForm onSuccess={onFormSentSuccess} />
        <Notifications target="change-password" />
      </Modal>
      <Alert
        title="Внимание"
        onClose={closeSuccess}
        isOpen={isSuccessVisible}
        message="Пароль успешно сохранен"
        buttons={[
          {
            title: 'Продолжить',
            'data-cy': 'EDIT_PASSWORD_ALERT_CONFIRM_BTN',
          },
        ]}
      />
    </React.Fragment>
  );
};
