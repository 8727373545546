import { useChatTdLk, useTasksTdLk } from '@features/api';
import { useDefaultCard } from '@teledoctor/common/dist/features/cards';
import { useCallback, useState, useEffect } from 'react';
import {
  AdditionalPayload,
  MessageUI,
} from '@eus/react-client/src/chat_v2/types';
import { ChatButtonsProps } from '../ui/chat-area/chat-buttons';
import { push } from '@features/app/navigation.model';

export const useButtonsLk = (taskId: number, taskIsComplete: boolean) => {
  const chatId = taskId.toString();

  const [chatButtons, setChatButtons] = useState<ChatButtonsProps>();

  const { card: defaultCard } = useDefaultCard();

  const { disableMessagesAtAdditionalPayloadType } = useChatTdLk();
  const { cancelMissedCall, clientCare } = useTasksTdLk();

  const cancelMissedCallHandler = useCallback(() => {
    cancelMissedCall(taskId).then(() => {
      disableMessagesAtAdditionalPayloadType(chatId, 'missed_call');
    });
  }, [
    cancelMissedCall,
    taskId,
    disableMessagesAtAdditionalPayloadType,
    chatId,
  ]);

  const clientCareNoHandler = useCallback(() => {
    clientCare({ taskId, type: 'no' }).then(() => {
      disableMessagesAtAdditionalPayloadType(chatId, 'client_care');
    });
  }, [clientCare, taskId, disableMessagesAtAdditionalPayloadType, chatId]);

  useEffect(() => {
    if (taskIsComplete) {
      setChatButtons(undefined);
    }
  }, [taskIsComplete]);

  const isNotButton = useCallback(
    (message: MessageUI) => {
      const additionalPayload: AdditionalPayload =
        message.additionalPayload && JSON.parse(message.additionalPayload);

      const type = additionalPayload?.type;

      if (type === 'select_timeslot' && !taskIsComplete) {
        setChatButtons({
          buttons: additionalPayload.enabled
            ? [
                {
                  title: 'Выбрать дату и время',
                  type: 'datetime',
                  chatId: taskId.toString(),
                },
              ]
            : [],
        });

        return !!message.text;
      }
      if (type === 'missed_call') {
        setChatButtons({
          buttons: additionalPayload.enabled
            ? [
                {
                  title: 'Да',
                  type: 'datetime',
                  chatId: taskId.toString(),
                },
                {
                  title: 'Нет',
                  type: 'regular',
                  onClick: cancelMissedCallHandler,
                },
              ]
            : [],
        });

        return false;
      }
      if (type === 'client_care') {
        setChatButtons({
          buttons: additionalPayload.enabled
            ? [
                {
                  title: 'Да',
                  type: 'regular',
                  onClick: () => {
                    if (defaultCard) {
                      push({
                        path: 'tasks/get',
                        state: {
                          card_id: defaultCard.card_client_id,
                          relatedTaskId: taskId,
                          isClientCare: true,
                        },
                      });
                    }
                  },
                },
                {
                  title: 'Нет',
                  type: 'regular',
                  onClick: clientCareNoHandler,
                },
              ]
            : [],
        });

        return false;
      }

      return true;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cancelMissedCallHandler, clientCareNoHandler, defaultCard, taskId],
  );

  return {
    chatButtons,
    isNotButton,
  };
};
