import { useCallback, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import authStyles from './../styles.module.scss';
import { AuthBack, CodeForm } from '@features/user/ui';
import { useAuthTdLk } from '@features/api';
import { addNotification, Notification } from '@ui';
import { CheckFormCode, RegisterFormCode } from '@eus/react-client';
import { ErrorOption } from 'react-hook-form';
import { requestCode } from '../helpers/auth';
import { CRM_ERRORS_RUSSIAN } from '@teledoctor/common/dist/lib/text-constants';

interface Props {
  phone?: string;
  onBackHandler?: () => void;
}

export const RegisterPage = ({ phone, onBackHandler }: Props) => {
  const [number, setNumber] = useState<string>('');
  const [isCodeSent, setCodeSentStatus] = useState<boolean>(false);

  const { requestRegisterCodeTd, registerByCodeTd } = useAuthTdLk();

  const requestCodeRegisterHandler = useCallback(
    (login: string) => {
      requestRegisterCodeTd(login, {
        onSuccess: () => {
          setCodeSentStatus(true);
        },
        errorHandler: (error) => {
          setCodeSentStatus(false);
          const notificationParams: Omit<Notification, 'message'> = {
            id: `auth-notification-${Date.now()}`,
            target: 'authorization',
            type: 'error',
          };

          Array.isArray(error)
            ? error.map((error) =>
                addNotification({
                  ...notificationParams,
                  message: error,
                }),
              )
            : addNotification({ ...notificationParams, message: error });
        },
      });
    },
    [requestRegisterCodeTd],
  );

  const onSuccessCode = useCallback(
    (
      { code }: CheckFormCode,
      setError: (name: keyof RegisterFormCode, error: ErrorOption) => void,
    ) => {
      registerByCodeTd({ code, phone: number }, (error) => {
        setError('code', {
          type: 'server',
          message: CRM_ERRORS_RUSSIAN[error] ?? error,
        });
      });
    },
    [registerByCodeTd, number],
  );

  useEffect(() => {
    if (!number) {
      requestCode({
        setNumber,
        requestMethod: requestCodeRegisterHandler,
        ...(phone && { phone }),
      });
    }
  }, [number, requestCodeRegisterHandler, phone]);

  return (
    <>
      {!!onBackHandler && (
        <AuthBack
          className={authStyles.back}
          onClick={() => {
            onBackHandler();
          }}
        />
      )}
      <h1 className="h1">Регистрация</h1>
      <div className={styles.tooltip}>
        <div className={styles['tooltip__icon']} />
        Пользователь не обнаружен в системе. Пройдите регистрацию
      </div>
      {!!number && !!isCodeSent && (
        <CodeForm
          phone={number}
          requestCodeAgain={requestCodeRegisterHandler}
          onSuccessForm={onSuccessCode}
        />
      )}
    </>
  );
};
