// TODO: ON NEW_CRM REWRITE
import { useStoreMap } from 'effector-react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { InputSelect, FieldWrapper, FField } from '@ui';

import { CreateFormValues } from '@teledoctor/common/dist/features/consultations/forms';
import {
  useServicesListLoadingSelector,
  useServicesListSelector,
} from '@eus/react-client';
import { Service } from '@eus/react-client/src/cards/types';

interface SelectServiceProps {
  name: string;
  cardId: number;
  methods: UseFormReturn<CreateFormValues>;
  onSelect?: (service: Service) => void;
}

export const SelectService = ({
  name,
  cardId,
  onSelect,
  methods,
}: SelectServiceProps) => {
  const services = useServicesListSelector({ cardClientId: cardId });
  const areServicesLoading = useServicesListLoadingSelector(cardId);

  const options =
    services?.map((service) => {
      return {
        label: service.name,
        value: service.id,
      };
    }) ?? [];

  const handleSelect = (id: string) => {
    const service = services?.find((service) => `${service.id}` === `${id}`);
    if (service) {
      onSelect?.(service);
    }
  };

  return !areServicesLoading && options.length === 0 ? (
    <div>По данной карте нет доступных услуг</div>
  ) : (
    <FField.Select
      name={name}
      methods={methods}
      label="Услуга"
      placeholder="Выберите услугу"
      options={options}
      className="form__field"
      dataCy="GET_CONSULT_SELECT_SERVICE"
      onFieldChange={handleSelect}
    />
  );
};
