import { AuthTypes } from '@teledoctor/common/dist/types/auth';
import { AuthPage, PhoneCheckSimple, RegisterPage } from '@features/user/ui';
import { AuthBlock } from '@features/user/ui/block';
import { AuthTemplate, Notifications, useCheckIdentifyAuthType } from '@ui';
import { useCallback, useState } from 'react';

export const AuthNewPage = () => {
  const [login, setLogin] = useState<string>('');
  const [authType, setAuthType] = useState<AuthTypes | ''>('');
  const [isChecked, setCheckingStatus] = useState<boolean>(false);

  const { handleCheckIdentify } = useCheckIdentifyAuthType({
    setAuthType,
    setCheckingStatus,
    beforeCheckingHandler: (login) => {
      setLogin(login);
    },
  });

  const getAuthComponent = useCallback(() => {
    if (isChecked && !!login) {
      switch (authType) {
        case 'auth':
          return (
            <AuthPage
              phone={login}
              onBackHandler={() => setCheckingStatus(false)}
            />
          );
        case 'register':
          return (
            <RegisterPage
              phone={login}
              onBackHandler={() => setCheckingStatus(false)}
            />
          );
      }
    }
  }, [isChecked, authType, login]);

  return (
    <AuthTemplate>
      <AuthBlock>
        {!isChecked ? (
          <>
            <h1 className="h1">Вход/Регистрация</h1>
            <PhoneCheckSimple
              onSuccessPhoneCheckHandler={(data) =>
                handleCheckIdentify(data.phone)
              }
              {...(!!login && { phone: login })}
            />
          </>
        ) : (
          getAuthComponent()
        )}
        <Notifications target="authorization" />
      </AuthBlock>
    </AuthTemplate>
  );
};
