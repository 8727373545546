import { FieldAddress } from './field-address';
import { FieldDate } from './field-date';
import { FieldDateTime } from './field-datetime';
import { FieldFile } from './field-file';
import { FieldInput } from './field-input';
import { FieldInputCheckbox } from './field-input-checkbox';
import { FieldInputMask } from './field-input-mask';
import { FieldInputPassword } from './field-input-password';
import { FieldInputRadio } from './field-input-radio';
import { FieldPhone } from './field-phone';
import { FieldSelect } from './field-select';
import { FieldTextArea } from './field-textarea';

export const FField = {
  Input: FieldInput,
  InputMask: FieldInputMask,
  InputPassword: FieldInputPassword,
  Radio: FieldInputRadio,
  Checkbox: FieldInputCheckbox,
  Select: FieldSelect,
  TextArea: FieldTextArea,
  Phone: FieldPhone,
  Date: FieldDate,
  DateTime: FieldDateTime,
  File: FieldFile,
  Address: FieldAddress,
};
export type { FieldDateProps } from './field-date';
export type { FieldDateTimeProps } from './field-datetime';
export type { FieldFileProps } from './field-file';
export type { FieldInputProps } from './field-input';
export type { FieldPhoneProps } from './field-phone';
export type { FieldSelectProps } from './field-select';
export type { FieldTextAreaProps } from './field-textarea';
