import classnames from 'classnames';
import React, { useEffect, useMemo, useRef } from 'react';
import ReactModal, { Props as ReactModalProps } from 'react-modal';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import './modal.scss';

const overlayClassNames = {
  base: 'modal__overlay',
  afterOpen: 'modal__overlay--after-open',
  beforeClose: 'modal__overlay--before-close',
};

export type CommonModalProps = Pick<ModalProps, 'onClose' | 'isOpen'>;
export interface ModalProps extends Omit<ReactModalProps, 'portalClassName'> {
  onClose: () => void;
  short?: boolean;
  header?: React.ReactNode;
  popupClassNames?: string;
  headerClassNames?: string;
  footer?: React.ReactNode;
  footerClassNames?: string;
  image?: string;
  imageClassNames?: string;
  closeIcon?: boolean;
  root?: React.ReactNode;
  contentClassNames?: string;
}

export const Modal: React.FC<ModalProps> = ({
  children,
  onClose,
  isOpen,
  className,
  short,
  header,
  popupClassNames,
  headerClassNames,
  footerClassNames,
  contentClassNames,
  footer,
  image,
  imageClassNames,
  closeIcon = true,
  root,
  ...props
}) => {
  const popupClasses = {
    base: classnames('modal__popup', popupClassNames, {
      'modal__popup--is-short': short,
      'modal__popup--with-image': Boolean(image),
    }),
    afterOpen: 'modal__popup--after-open',
    beforeClose: 'modal__popup--before-close',
  };
  const portalClassNames = classnames(
    'modal',
    typeof className === 'string' && className,
  );

  const contentRef = useRef<HTMLDivElement | null>(null);

  const renderHeader = useMemo(() => {
    if (!header) {
      return null;
    }

    const innerComponent =
      typeof header === 'string' ? (
        <h1 className="h2" data-cy="MODAL_TITLE">
          {header}
        </h1>
      ) : (
        header
      );

    return (
      <div className={classnames('modal__header', headerClassNames)}>
        {innerComponent}
      </div>
    );
  }, [header, headerClassNames]);

  const renderContent = useMemo(() => {
    if (!image) {
      return (
        <div
          className={classnames('modal__content', contentClassNames)}
          data-scroll-lock-scrollable>
          {children}
        </div>
      );
    }

    return (
      <div
        className={classnames('modal__content', contentClassNames)}
        data-scroll-lock-scrollable>
        <div className="modal__text">{children}</div>
        <div className={`modal__image ${imageClassNames ?? ''}`}>
          <img
            src={image}
            title={typeof header === 'string' ? header : undefined}
          />
        </div>
      </div>
    );
  }, [children, header, image, imageClassNames, contentClassNames]);

  const handleAfterOpen = () => {
    disablePageScroll();
  };
  const handleAfterClose = () => {
    enablePageScroll();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      closeTimeoutMS={30}
      className={popupClasses}
      portalClassName={portalClassNames}
      overlayClassName={overlayClassNames}
      overlayRef={(node) => {
        contentRef.current = node;
      }}
      onAfterOpen={handleAfterOpen}
      onAfterClose={handleAfterClose}
      {...props}
      onRequestClose={onClose}>
      {closeIcon && (
        <button
          className="modal__close modal-close"
          onClick={onClose}
          data-cy="MODAL_CLOSE_BTN"
        />
      )}
      {renderHeader}
      {renderContent}
      {footer ? (
        <div className={classnames('modal__footer', footerClassNames)}>
          {footer}
        </div>
      ) : null}
      {root}
    </ReactModal>
  );
};
