import React, { useEffect } from 'react';
import './styles.scss';
import { TitleLine } from '@ui';
import { ProfileInfo, EditPassword } from '@features/user/ui';
import { useProfileTdLk } from '../../features/api';

export const ProfilePage = () => {
  const { profile, load } = useProfileTdLk();

  useEffect(() => {
    if (!profile) {
      load();
    }
  }, [load, profile]);

  return (
    <React.Fragment>
      <TitleLine titleText="Профиль" className="titleline__profile-page">
        <EditPassword />
      </TitleLine>
      {profile && <ProfileInfo user={profile} />}
    </React.Fragment>
  );
};
