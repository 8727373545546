import { useCallback, useEffect, useState, useMemo } from 'react';
import { Controller, FieldErrors, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ChangeAvatar } from './change-avatar';
import { Button, FField, FormWrapper } from '@ui';
import { useProfileEditFormSchema } from '@teledoctor/common/dist/features/user/forms';
import { useSexSelectValues } from '@teledoctor/common/dist/features/shared/forms';
import { EditProfileFormData } from '@teledoctor/common/dist/features/user';
import { ServerErrorsHandler } from '@eus/react-client/src/forms/serverErrorsHandler';
import { useProfileTdLk } from '../../../../api';
import { formServerErrorHandler } from '@teledoctor/common/dist/lib/error-handlers';
import { addNotification } from '../../../../../ui/organisms/notifications/model';
import { FeedParams } from '@teledoctor/common/dist/lib/requests';
import { SelectCountry } from '@features/shared/ui';

interface ProfileEditFormProps {
  defaultValues: Omit<EditProfileFormData, 'avatar'>;
  onSuccess: () => void;
}

export const ProfileEditForm = ({
  defaultValues,
  onSuccess,
}: ProfileEditFormProps) => {
  const [addressString, setAddressString] = useState(
    defaultValues?.address ? JSON.parse(defaultValues?.address || '') : {},
  );

  const { ProfileEditFormSchema } = useProfileEditFormSchema();
  const methods = useForm<EditProfileFormData>({
    resolver: yupResolver(ProfileEditFormSchema),
    mode: 'onBlur',
    defaultValues: {
      ...defaultValues,
      phone: `+${defaultValues.phone}`,
      address: addressString,
    },
  });
  const addressChange = methods.watch('address');

  useEffect(() => {
    const newAddress = methods.getValues('address');
    if (newAddress && newAddress !== addressString) {
      setAddressString(newAddress);
    }
  }, [addressChange, methods, addressString]);

  const { sendEditedProfile } = useProfileTdLk();

  const handleErrors = (errors: FieldErrors<EditProfileFormData>) => {
    console.log('@ERRORS', errors);
  };

  const onSubmit = (values: EditProfileFormData) => {
    const stringifiedAddress = JSON.stringify(values.address);

    const newAddress =
      stringifiedAddress !== '{}' ? JSON.stringify(values.address) : '';

    const data = {
      ...values,
      address: newAddress,
    };

    const nonEmptyValues = Object.keys(data).reduce(
      (res, key) =>
        data[key] !== undefined ? { ...res, [key]: data[key] } : res,
      {},
    ) as EditProfileFormData;

    sendEditedProfile(
      nonEmptyValues,
      new FeedParams({
        needShowLoader: true,
        throwNext: true,
        ignoreError: true,
      }),
    )
      .then(() => onSuccess())
      .catch(
        formServerErrorHandler({
          commonErrorCase: {
            commonErrorCallback: (errorText) =>
              addNotification({
                id: 'profile' + new Date(),
                type: 'error',
                message: errorText,
                target: 'global',
              }),
          },
          fieldsErrorsCase: {
            fieldsErrorsCallback: ServerErrorsHandler<EditProfileFormData>(
              methods.setError,
            ),
          },
        }),
      );
  };

  const sex = useMemo(() => methods.watch('sex'), [methods]);

  const renderAvatar = useCallback(() => <ChangeAvatar sex={sex} />, [sex]);
  const { sexSelectValues } = useSexSelectValues();

  return (
    <section className="block edit-profile edit-profile__active">
      <div className="block__columns">
        <div className="block__column-left">
          <Controller
            name="avatar"
            control={methods.control}
            render={renderAvatar}
          />
        </div>
        <div className="block__column-right">
          <FormWrapper
            className="edit-profile__form"
            onSubmit={methods.handleSubmit(onSubmit, handleErrors)}>
            <div className="form__row -mb__xmedium -mb__xs-xsmall">
              <FField.Input
                name="first_name"
                methods={methods}
                label="Имя"
                placeholder="Введите Ваше имя"
                type="text"
                className="form__field"
                disabled
                data-cy="PROFILE_FIRSTNAME_INPUT"
              />
              <FField.Input
                name="last_name"
                methods={methods}
                label="Фамилия"
                placeholder="Введите Вашу фамилию"
                type="text"
                className="form__field"
                disabled
                data-cy="PROFILE_LASTNAME_INPUT"
              />
              <FField.Input
                name="middle_name"
                methods={methods}
                label="Отчество"
                disabled={defaultValues.middle_name ? true : false}
                placeholder="Введите Ваше отчество"
                type="text"
                className="form__field"
                data-cy="PROFILE_MIDDLENAME_INPUT"
              />
              <FField.Select
                name="sex"
                methods={methods}
                label="Пол"
                placeholder="Выберите пол"
                options={sexSelectValues}
                className="form__field"
                disabled
                dataCy="PROFILE_SEX_SELECT"
              />
              <FField.Date
                name="date_of_birth"
                methods={methods}
                label="Дата рождения"
                className="form__field"
                maxDate={new Date()}
              />
              <SelectCountry
                name="country_code"
                control={methods.control}
                label="Страна"
                errors={methods.formState.errors}
                fieldClassname="form__field"
                isClearable={true}
                dataCy="profile_select_country"
              />
              <FField.Address
                name={'address'}
                methods={methods}
                label={'Адрес проживания'}
                placeholder={'Укажите адрес проживания'}
                className={'form__field'}
                data-cy={'profile_edit_addres_input'}
                defaultValue={addressString}
                value={addressString}
                dataCy="profile_address"
              />
              <FField.Phone
                name="phone"
                label="Номер телефона"
                methods={methods}
                className="form__field"
                dataCyCodeSelect="PROFILE_PHONE_CODE_SELECT"
                data-cy="PROFILE_PHONE_INPUT"
                disabled
              />
              <FField.Input
                name="email"
                methods={methods}
                label="E-mail"
                placeholder="Введите email"
                type="email"
                className="form__field"
                disabled
                data-cy="PROFILE_EMAIL_INPUT"
              />
            </div>
            <div className="form__row">
              <Button
                className="button button__primary -width__xs-100"
                title="Сохранить"
                data-cy="PROFILE_SUBMIT_BTN"
                type="submit"
              />
            </div>
          </FormWrapper>
        </div>
      </div>
    </section>
  );
};
