import { yupResolver } from '@hookform/resolvers/yup';
import {
  PhoneFormValues,
  useCheckPhoneFormSchema,
} from '@teledoctor/common/dist/features/user/forms';
import { Button, FField, FormWrapper } from '@ui';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

export interface Props {
  phone?: string;
  onSuccessPhoneCheckHandler: ({ phone }: PhoneFormValues) => void;
}

export const PhoneCheckSimple = ({
  onSuccessPhoneCheckHandler,
  phone,
}: Props) => {
  const { CheckPhoneFormSchema } = useCheckPhoneFormSchema();
  const methods = useForm<PhoneFormValues>({
    shouldUnregister: false,
    resolver: yupResolver(CheckPhoneFormSchema),
    mode: 'onBlur',
  });

  const { setValue } = methods;

  useEffect(() => {
    if (phone) {
      setValue('phone', phone);
    }
  }, [phone, setValue]);

  return (
    <FormWrapper
      onSubmit={methods.handleSubmit(onSuccessPhoneCheckHandler, () =>
        console.log('error'),
      )}>
      <FField.Phone
        id="enter-form-phone"
        name="phone"
        label="Номер телефона"
        methods={methods}
        className="form__field field--no-border"
        dataCyCodeSelect="AUTH_PHONE_CODE_SELECT"
        data-cy="AUTH_PHONE_INPUT"
      />
      <Button fullWidth title="Продолжить" />
    </FormWrapper>
  );
};
